.customer-info {
  .name {
    margin-top: 16px;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
  }

  text-align: left;
  margin-left: 16px;
}
