.new-customer-form {
  @media screen and (min-width: 750px) {
    margin: auto;
    width: 35%;
  }

  .form-input {
    margin-top: 16px;
  }
}
