.text-message-page {
  .column-left {
    text-align: left;
  }

  .column-right {
    text-align: right;

    .text-image {
      max-height: 250px;
      max-width: 250px;
    }
  }
}
