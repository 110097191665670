.customer-show-page {
  text-align: left;

  .main-buttons-container {
    margin: 24px 0;

    .buttons {
      margin: 8px;
    }
  }

  .open-orders-container {
    margin: 24px 8px;
  }

  .paid-orders-button-container {
    margin: 24px 8px;
    text-align: left;
  }
}
